export default {
  path: "/",
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  meta: {
    // requiresAuth: true
  },
  redirect: () => {
    // the function receives the target route as the argument
    // a relative location doesn't start with `/`
    // or { path: 'profile'}
    return "home";
  },
  components: {
    default: () =>
      import(
        /* webpackChunkName: "AppLayout" */ "../components/Layout/AppLayout.vue"
      ),
    // default: () => import(/* webpackChunkName: "Home" */ '../pages/Welcome.vue'),
    // default: () => import(/* webpackChunkName: "AppLayout" */ '../components/Layout/AppLayout.vue'),
  },
  children: [
    {
      path: "/welcome",
      meta: {
        requiresAuth: false,
        pwaCheck: true,
      },
      component: () =>
        import(/* webpackChunkName: "Service" */ "../pages/Welcome.vue"),
    },
    {
      path: "/guest",
      meta: {
        requiresAuth: false,
        mode: "guest",
      },
      components: {
        default: () =>
          import(/* webpackChunkName: "Home" */ "../pages/Guest.vue"),
        Footer: () =>
          import(
            /* webpackChunkName: "Footer" */ "../components/Layout/Footer.vue"
          ),
        // NotificationBar: () => import(/* webpackChunkName: "NotificationBar" */ '../components/Layout/NotificationBar.vue'),
      },
    },
    {
      path: "/guest/articles/:id",
      meta: {
        requiresAuth: false,
        mode: "guest",
      },
      components: {
        default: () =>
          import(/* webpackChunkName: "Article" */ "../pages/Article.vue"),
      },
    },
    {
      path: "/home",
      meta: {
        requiresAuth: false,
      },
      components: {
        default: () =>
          import(/* webpackChunkName: "Home" */ "../pages/Home.vue"),
        Footer: () =>
          import(
            /* webpackChunkName: "Footer" */ "../components/Layout/Footer.vue"
          ),
        NotificationBar: () =>
          import(
            /* webpackChunkName: "NotificationBar" */ "../components/Layout/NotificationBar.vue"
          ),
      },
    },
    {
      path: "/service",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(/* webpackChunkName: "Service" */ "../pages/Service.vue"),
        Footer: () =>
          import(
            /* webpackChunkName: "Footer" */ "../components/Layout/Footer.vue"
          ),
        NotificationBar: () =>
          import(
            /* webpackChunkName: "NotificationBar" */ "../components/Layout/NotificationBar.vue"
          ),
      },
    },
    {
      path: "/service/on-site-activities",
      meta: {
        requiresAuth: true,
        publisher: "municipal",
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "ActiveQuestions" */ "../pages/ActiveQuestions.vue"
          ),
        Footer: () =>
          import(
            /* webpackChunkName: "Footer" */ "../components/Layout/Footer.vue"
          ),
      },
    },
    {
      path: "/service/questions",
      meta: {
        requiresAuth: false,
        publisher: "municipal",
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "ActiveQuestions" */ "../pages/ActiveQuestions.vue"
          ),
        Footer: () =>
          import(
            /* webpackChunkName: "Footer" */ "../components/Layout/Footer.vue"
          ),
      },
    },
    {
      path: "/service/survey/:status",
      meta: {
        requiresAuth: true,
        publisher: "municipal",
        campaignType: "questionnaire",
      },
      components: {
        default: () =>
          import(/* webpackChunkName: "Survey" */ "../pages/Survey.vue"),
        Footer: () =>
          import(
            /* webpackChunkName: "Footer" */ "../components/Layout/Footer.vue"
          ),
      },
    },
    {
      path: "/service/e-voting/:status",
      meta: {
        requiresAuth: true,
        publisher: "municipal",
        campaignType: "vote",
      },
      components: {
        default: () =>
          import(/* webpackChunkName: "Survey" */ "../pages/Survey.vue"),
        Footer: () =>
          import(
            /* webpackChunkName: "Footer" */ "../components/Layout/Footer.vue"
          ),
      },
    },
    {
      path: "/service/question-detail/:id",
      meta: {
        requiresAuth: false,
        publisher: "municipal",
      },
      components: {
        default: () => import(/* webpackChunkName: "QA" */ "../pages/QADetail.vue"),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/service/qa/:id",
      meta: {
        requiresAuth: false,
        publisher: "municipal",
      },
      components: {
        default: () => import(/* webpackChunkName: "QA" */ "../pages/QA.vue"),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/service/qre/:id",
      meta: {
        requiresAuth: true,
        publisher: "municipal",
      },
      components: {
        default: () =>
          import(/* webpackChunkName: "QA" */ "../pages/Questionnaire.vue"),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/service/check-in/:id",
      meta: {
        requiresAuth: true,
        publisher: "municipal",
      },
      components: {
        default: () =>
          import(/* webpackChunkName: "CheckIn" */ "../pages/CheckIn.vue"),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/scanner",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(/* webpackChunkName: "Scanner" */ "../pages/Scanner.vue"),
        Footer: () =>
          import(
            /* webpackChunkName: "Footer" */ "../components/Layout/Footer.vue"
          ),
        NotificationBar: () =>
          import(
            /* webpackChunkName: "NotificationBar" */ "../components/Layout/NotificationBar.vue"
          ),
      },
    },
    {
      path: "/tickets",
      meta: {
        requiresAuth: true,
      },
      component: () =>
        import(/* webpackChunkName: "Tickets" */ "../pages/Tickets.vue"),
    },
    {
      path: "/tickets/:ticketId",
      meta: {
        requiresAuth: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "TicketDetail" */ "../pages/TicketDetail.vue"
        ),
    },
    {
      path: "/profile",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(/* webpackChunkName: "Profile" */ "../pages/Profile.vue"),
        Footer: () =>
          import(
            /* webpackChunkName: "Footer" */ "../components/Layout/Footer.vue"
          ),
        NotificationBar: () =>
          import(
            /* webpackChunkName: "NotificationBar" */ "../components/Layout/NotificationBar.vue"
          ),
      },
    },
    {
      path: "/map",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "AppointStoreMap" */ "../pages/AppointStoreMap.vue"
          ),
        Footer: () =>
          import(
            /* webpackChunkName: "Footer" */ "../components/Layout/Footer.vue"
          ),
        NotificationBar: () =>
          import(
            /* webpackChunkName: "NotificationBar" */ "../components/Layout/NotificationBar.vue"
          ),
      },
    },
    {
      path: "/record",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(/* webpackChunkName: "Record" */ "../pages/Record.vue"),
        Footer: () =>
          import(
            /* webpackChunkName: "Footer" */ "../components/Layout/Footer.vue"
          ),
        NotificationBar: () =>
          import(
            /* webpackChunkName: "NotificationBar" */ "../components/Layout/NotificationBar.vue"
          ),
      },
    },
    {
      path: "/payment-record",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "PaymentRecord" */ "../pages/PaymentRecord.vue"
          ),
        Footer: () =>
          import(
            /* webpackChunkName: "Footer" */ "../components/Layout/Footer.vue"
          ),
        NotificationBar: () =>
          import(
            /* webpackChunkName: "NotificationBar" */ "../components/Layout/NotificationBar.vue"
          ),
      },
    },
    {
      path: "/point-list",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(/* webpackChunkName: "PointList" */ "../pages/PointList.vue"),
        Footer: () =>
          import(
            /* webpackChunkName: "Footer" */ "../components/Layout/Footer.vue"
          ),
      },
    },
    {
      path: "/qa/:id",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () => import(/* webpackChunkName: "QA" */ "../pages/QA.vue"),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/appoint-store",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "AppointStore" */ "../pages/AppointStore.vue"
          ),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/barcode",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(/* webpackChunkName: "Profile" */ "../pages/Barcode.vue"),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue'),
        // NotificationBar: () => import(/* webpackChunkName: "NotificationBar" */ '../components/Layout/NotificationBar.vue'),
      },
    },
    // {
    //     path: '/consumption',
    //     meta: {
    //         requiresAuth: true
    //     },
    //     components: {
    //         default: () => import(/* webpackChunkName: "Consumption" */ '../pages/Consumption.vue'),
    //         // default: () => import(/* webpackChunkName: "Consumption" */ '../pages/PointPayment.vue'),
    //         // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
    //     }
    // },
    // {
    //     path: '/consumption-result',
    //     meta: {
    //         requiresAuth: true
    //     },
    //     components: {
    //         default: () => import(/* webpackChunkName: "ConsumptionResult" */ '../pages/ConsumptionResult.vue'),
    //         // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
    //     }
    // },
    {
      path: "/profile/edit",
      meta: {
        requiresAuth: false,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "ProfileEdit" */ "../pages/ProfileEdit.vue"
          ),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/notification",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "Notification" */ "../pages/Notification.vue"
          ),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/notification-setting",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "Notification" */ "../pages/NotificationSetting.vue"
          ),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/parking-coupons",
      meta: {
        requiresAuth: false,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "ParkingCoupon" */ "../pages/ParkingCoupons.vue"
          ),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/parking-coupons/detail/:id",
      meta: {
        requiresAuth: false,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "ParkingCoupon" */ "../pages/ParkingCouponsDetail.vue"
          ),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/parking-coupons/confirm/:id",
      meta: {
        requiresAuth: false,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "ParkingCoupon" */ "../pages/ParkingCouponsConfirm.vue"
          ),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/digital-card",
      meta: {
        requiresAuth: false,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "ParkingCoupon" */ "../pages/DigitalCard/index.vue"
          ),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/digital-card/add",
      meta: {
        requiresAuth: false,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "DigitalCard" */ "../pages/DigitalCard/add.vue"
          ),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/digital-card/change",
      meta: {
        requiresAuth: false,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "DigitalCard" */ "../pages/DigitalCard/change.vue"
          ),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/digital-card/task",
      meta: {
        requiresAuth: false,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "DigitalCard" */ "../pages/DigitalCard/task.vue"
          ),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/digital-card/borrowing-list",
      meta: {
        requiresAuth: false,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "DigitalCard" */ "../pages/DigitalCard/borrowingList.vue"
          ),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/digital-card/reserve",
      meta: {
        requiresAuth: false,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "DigitalCard" */ "../pages/DigitalCard/reserve.vue"
          ),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/healthy-step",
      meta: {
        requiresAuth: false,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "HealthyStep" */ "../pages/HealthyStep.vue"
          ),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/store-management",
      meta: {
        requiresAuth: false,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "StoreManagement" */ "../pages/StoreManagement/index.vue"
          ),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/store-management/add",
      meta: {
        requiresAuth: false,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "StoreManagement" */ "../pages/StoreManagement/add.vue"
          ),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/store-management/edit/:id",
      meta: {
        requiresAuth: false,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "StoreManagement" */ "../pages/StoreManagement/edit.vue"
          ),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/store-management/record",
      meta: {
        requiresAuth: false,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "StoreManagement" */ "../pages/StoreManagement/record.vue"
          ),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/store-management/detail",
      meta: {
        requiresAuth: false,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "StoreManagement" */ "../pages/StoreManagement/detail.vue"
          ),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/store-management/service-setting",
      meta: {
        requiresAuth: false,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "StoreManagement" */ "../pages/StoreManagement/serviceSetting.vue"
          ),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/store-management/access-setting",
      meta: {
        requiresAuth: false,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "StoreManagement" */ "../pages/StoreManagement/accessSetting.vue"
          ),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/store-management/notify-setting",
      meta: {
        requiresAuth: false,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "StoreManagement" */ "../pages/StoreManagement/notifySetting.vue"
          ),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/store-management/payment-record",
      meta: {
        requiresAuth: false,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "StoreManagement" */ "../pages/StoreManagement/paymentRecord.vue"
          ),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/store-management/payment-record-history",
      meta: {
        requiresAuth: false,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "StoreManagement" */ "../pages/StoreManagement/paymentRecordHistory.vue"
          ),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/campaign",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(/* webpackChunkName: "Campaign" */ "../pages/Campaign.vue"),
        Footer: () =>
          import(
            /* webpackChunkName: "Footer" */ "../components/Layout/Footer.vue"
          ),
      },
    },
    {
      path: "/campaign/:id/detail",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "QADetail" */ "../pages/QADetail.vue"
          ),
      },
      // }, {
      //     path: '/questions',
      //     meta: {
      //         requiresAuth: true
      //     },
      //     components: {
      //         default: () => import(/* webpackChunkName: "ActiveQuestions" */ '../pages/ActiveQuestions.vue'),
      //         Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      //     }
    },
    {
      path: "/transfer",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(/* webpackChunkName: "Transfer" */ "../pages/Transfer.vue"),
      },
    },
    {
      path: "/transfer-result",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "TransferResult" */ "../pages/TransferResult.vue"
          ),
      },
    },
    {
      path: "/articles",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(/* webpackChunkName: "Articles" */ "../pages/Articles.vue"),
      },
    },
    {
      path: "/articles/:id",
      meta: {
        requiresAuth: false,
      },
      components: {
        default: () =>
          import(/* webpackChunkName: "Article" */ "../pages/Article.vue"),
      },
    },
    {
      path: "/campaign-records",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "CampaignRecords" */ "../pages/CampaignRecords.vue"
          ),
      },
    },
    {
      path: "/stores",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(/* webpackChunkName: "Stores" */ "../pages/Stores.vue"),
        Footer: () =>
          import(
            /* webpackChunkName: "Footer" */ "../components/Layout/Footer.vue"
          ),
      },
    },
    {
      path: "/stores/:id/stamp-cards",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "StoreStamps" */ "../pages/StoreStampCards.vue"
          ),
      },
    },
    {
      path: "/stores/:id/info",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(/* webpackChunkName: "StoreInfo" */ "../pages/StoreInfo.vue"),
      },
    },
    {
      path: "/stamp-cards",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "MyStampCards" */ "../pages/MyStampCards.vue"
          ),
      },
    },
    {
      path: "/stamp-cards/:id",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(/* webpackChunkName: "StampCard" */ "../pages/StampCard.vue"),
      },
    },
    {
      path: "/carrier/edit",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "ProfileEdit" */ "../pages/CarrierEdit.vue"
          ),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/websites",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(/* webpackChunkName: "Websites" */ "../pages/Websites.vue"),
      },
    },
    {
      path: "/code-exchange",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "CodeExchange" */ "../pages/CodeExchange.vue"
          ),
      },
    },
    {
      path: "/profile/edit/phone",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "Notification" */ "../pages/ProfileEditPhone.vue"
          ),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/B11",
      meta: {
        requiresAuth: false,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "Notification" */ "../pages/uide-B11.vue"
          ),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/E2",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(/* webpackChunkName: "Notification" */ "../pages/uide-E2.vue"),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/B1-2/:id",
      meta: {
        requiresAuth: false, // arnold-TODO
        //requiresAuth: false,
        publisher: "municipal",
      },
      components: {
        default: () =>
          import(/* webpackChunkName: "QA" */ "../pages/QADetail.vue"),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/B1",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(/* webpackChunkName: "Notification" */ "../pages/uide-B1.vue"),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/B1-3/:id",
      meta: {
        requiresAuth: false,
        publisher: "municipal",
      },
      components: {
        default: () => import(/* webpackChunkName: "QA" */ "../pages/QA.vue"),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/B1-1",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "Notification" */ "../pages/uide-B1-1.vue"
          ),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/B2",
      meta: {
        requiresAuth: false,
      },
      components: {
        default: () =>
          import(/* webpackChunkName: "Notification" */ "../pages/uide-B2.vue"),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/B2-detail/:id",
      meta: {
        requiresAuth: false,
      },
      components: {
        default: () =>
          import(/* webpackChunkName: "Notification" */ "../pages/B2Detail.vue"),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
      },
    },
    {
      path: "/transfer-record",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "PaymentRecord" */ "../pages/TransferRecord.vue"
          ),
        Footer: () =>
          import(
            /* webpackChunkName: "Footer" */ "../components/Layout/Footer.vue"
          ),
        NotificationBar: () =>
          import(
            /* webpackChunkName: "NotificationBar" */ "../components/Layout/NotificationBar.vue"
          ),
      },
    },
    {
      path: "/record-detail/:id",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "PaymentRecord" */ "../pages/RecordDetail.vue"
          ),
      },
    },
    {
      path: "/payment-record-detail",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "PaymentRecord" */ "../pages/PaymentRecordDetail.vue"
          ),
      },
    },
    {
      path: "/transfer-record-detail",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "PaymentRecord" */ "../pages/PaymentRecordDetail.vue"
          ),
      },
    },
    {
      path: "/send",
      meta: {
        requiresAuth: false,
      },
      components: {
        default: () =>
          import(/* webpackChunkName: "Send" */ "../pages/Send.vue"),
      },
    },
    {
      path: "/customer-service",
      meta: {
        requiresAuth: false,
      },
      components: {
        default: () =>
          import(
            /* webpackChunkName: "CustomerService" */ "../pages/CustomerService.vue"
          ),
      },
    },
    {
      path: "/setting",
      meta: {
        requiresAuth: true,
      },
      components: {
        default: () =>
          import(/* webpackChunkName: "Setting" */ "../pages/Settings.vue"),
      },
    },
    {
      path: '/coin-rule',
      meta: {
        requiresAuth: true
      },
      components: {
        default: () => import(/* webpackChunkName: "Consumption" */ '../pages/CoinRule.vue')
      }
    },
    {
      path: '/E11',
      meta: {
        requiresAuth: true
      },
      components: {
        default: () => import(/* webpackChunkName: "Consumption" */ '../pages/E11.vue')
      }
    },
    {
      path: 'static',
      meta: {
        requiresAuth: true
      },
      components: {
        default: () => import(/* webpackChunkName: "Consumption" */ '../pages/Static.vue')
      }
    }
    // {
    //     path: '/point-payment',
    //     meta: {
    //     requiresAuth: true,
    //     },
    //     components: {
    //     default: () =>
    //         import(/* webpackChunkName: "Payment" */ '../pages/PointPayment.vue'),
    //     // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
    //     }
    // },
    // {
    //     path: '/payment',
    //     meta: {
    //         requiresAuth: true
    //     },
    //     components: {
    //         default: () => import(/* webpackChunkName: "Consumption" */ '../pages/Payment.vue'),

    //         // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
    //     }
    // },
    // {
    //     path: '/payment-result',
    //     meta: {
    //         requiresAuth: true
    //     },
    //     components: {
    //         default: () => import(/* webpackChunkName: "PaymentResult" */ '../pages/PaymentResult.vue'),
    //         // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
    //     }
    // },{
    //     path: '/payment-error',
    //     meta: {},
    //     components: {
    //         default: () => import(/* webpackChunkName: "Error" */ '../pages/PaymentError.vue'),
    //         // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
    //     }
    // }
  ],
};
